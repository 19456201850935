<template>
  <div
    class="flex relative z-10 whitespace-nowrap overflow-x-auto text-sm sm:text-base px-4 sm:px-0"
    aria-label="Tabs"
    ref="pageTabs"
  >
    <TheNuxtLink
      v-for="tab in tabs.items"
      :key="tab.name"
      :to="tab.slug ? `${tabs.rootPath}/${tab.slug}` : tabs.rootPath"
      @click="changeTab(tab.slug)"
      class="overflow-hidden relative block px-4 py-2 min-w-[6rem] font-medium text-center transition-all flex-shrink-0"
      :class="{
        'opacity-50 hover:opacity-90': tab.slug !== tabs.current,
        'opacity-100': tab.slug === tabs.current,
      }"
      :aria-current="tabs.current === tab.slug ? 'page' : undefined"
      :ref="setItemRef"
    >
      <span
        class="font-bold opacity-0 transition-all"
        :class="tabs.current === tab.slug && 'opacity-100'"
      >
        {{ tab.name }}
      </span>
      <div
        class="absolute inset-0 px-4 py-2 transition-all"
        :class="tabs.current === tab.slug && 'opacity-0'"
      >
        {{ tab.name }}
      </div>
    </TheNuxtLink>
    <div class="selector"></div>
  </div>
</template>

<script setup lang="ts">
import type { ComponentPublicInstance } from "vue";

const TheNuxtLink = resolveComponent("TheNuxtLink");

type Tab = {
  name: string;
  slug: string;
};

interface Props {
  tabs: {
    current: string;
    default: string;
    rootPath: string;
    items: Tab[];
  };
  ignoreSubPaths?: string[];
}

interface Events {
  (e: "changeTab", v: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();
const route = useRoute();

const pageTabs = ref<HTMLDivElement>();

function changeTab(selectedTab: string) {
  emit("changeTab", selectedTab);

  if (!pageTabs.value?.getBoundingClientRect()) return;

  const { top } = pageTabs.value.getBoundingClientRect();

  if (top < window.scrollY) {
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
}

const tabHighlightLeft = ref(0);
const tabHighlightWidth = ref(0);
const itemRefs = ref<HTMLAnchorElement[]>([]);
const setItemRef = (el: Element | ComponentPublicInstance | null) => {
  if ((el as ComponentPublicInstance)?.$el)
    itemRefs.value.push(
      (el as ComponentPublicInstance).$el as HTMLAnchorElement,
    );
};

onBeforeUpdate(() => {
  itemRefs.value = [];
});

watch(
  () => props.tabs.current,
  () => setHighlightPosition(),
);

// remove the trailing slash from url
const removeTrailingSlash = (url: string) => {
  const lastSlashIndex = url.lastIndexOf("/");
  if (url && lastSlashIndex > -1 && lastSlashIndex === url.length - 1)
    return url.substring(0, url.length - 1);
  return url;
};

watch(
  () => route.path,
  (v) => {
    const trailingSlashRemoved = removeTrailingSlash(v);
    if (
      props.ignoreSubPaths?.some(
        (path) =>
          trailingSlashRemoved.includes(path) &&
          trailingSlashRemoved.indexOf(path) + path.length <
            trailingSlashRemoved.length,
      )
    ) {
      return;
    }

    const found = props.tabs.items.find(
      (tab) =>
        (tab.slug
          ? `${props.tabs.rootPath}/${tab.slug}`
          : props.tabs.rootPath) === trailingSlashRemoved,
    );
    if (found) {
      props.tabs.current = found.slug;
    } else {
      navigateTo({
        path: `${props.tabs.rootPath}/${
          props.tabs.default ?? props.tabs.items[0].slug
        }`.replace(/\/$/, ""),
        replace: true,
      });
    }
  },
  { immediate: true },
);

const setHighlightPosition = () => {
  const index = props.tabs.items.findIndex(
    (tab) => tab.slug === props.tabs.current,
  );

  const tabRef = itemRefs.value[index];

  tabHighlightLeft.value = Math.max(tabRef?.offsetLeft ?? 1, 0);
  tabHighlightWidth.value = Math.max((tabRef?.clientWidth ?? 0) - 2, 0);
};

onMounted(() => {
  window.addEventListener("resize", setHighlightPosition);

  setTimeout(() => {
    setHighlightPosition();
  }, 500);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", setHighlightPosition);
});
</script>

<style scoped lang="postcss">
.selector {
  width: calc(v-bind(tabHighlightWidth) * 1px);
  left: calc(v-bind(tabHighlightLeft) * 1px);
  height: 2px;

  @apply absolute bg-nami-comi-blue bottom-0 transition-all;
}
</style>
